<template>
  <ScheduleWidgets />
</template>

<script>
import ScheduleWidgets from "../schedule-widgets"

export default {
  name: "ScheduleWidgets2",
  components: {
    ScheduleWidgets
  }
}
</script>
